<template>
  <div class="order giac">
    <Title title="购买门票"></Title>

    <div class="common" v-if="active === 'common'">
      <BuyTicket></BuyTicket>
      <Tips :meetTime="meetTime"></Tips>
    </div>

    <iframe
      v-if="active === 'special'"
      style="width: 100%; height: 1500px; margin-top: 120px"
      :src="`https://case.msup.com.cn/pay/pay-submit.html?hash=${hash}`"
    ></iframe>

    <div class="line"></div>
  </div>
</template>

<script>
import { sid, checkHash, getMeetingTime } from "@/utils/api.js";
export default {
  name: "Order",
  components: {
    Title: () => import("@/components/Title"),
    Tips: () => import("@/components/order/Tips.vue"),
    BuyTicket: () => import("@/components/order/BuyTicket.vue"),
  },
  data() {
    return {
      type: "",
      meetTime: "",
      hash: "",
      sid: "",
      active: "common",
    };
  },
  created() {},
  mounted() {
    this.getTime();
    this.isHashTrue();
  },
  methods: {
    getTime() {
      this.$http
        .jsonp(`${getMeetingTime}${sid(this.global.year)}`)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
        //   console.log(res);
          this.meetTime = this.formatTime(res.data.startTime, "start");
        });
    },
    formatTime(timestamp, tag) {
      const time = new Date(timestamp * 1000);
      const year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (tag === "start") {
        return `${year}年${month}月${day}日`;
      } else {
        return `${month}.${day}`;
      }
    },
    //判断hash是否合格(qd是否正确)
    isHashTrue() {
      // const { hash, sid, type } = this.$route.query;
      // this.hash = hash;
      // this.sid = sid;
      // this.type = type;

      // console.log(this.global.hashParam);
      this.hash = this.$route.query.qd ? this.$route.query.qd : this.global.hashParam.qd;
      if (this.hash === undefined) {
        return false;
      } else {
        this.global.hashParam.qd = this.hash;
      }

      this.$http
        .jsonp(`${checkHash}?hash=${this.hash}`)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.status === "200") {
            console.log(this.hash, res);
            this.active = "special";
            // window.open(
            //   `https://www.msup.com.cn/shopping/default/shopping-tickect?sid=${this.sid}&amp;qd=${this.hash}`
            // );
          } else {
            this.active = "common";
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/reset.css";

.giac {
  --main-color: #003177;
}
.order {
  .line {
    width: 100%;
    max-width: 1200px;
    height: 1px;
    margin: 0 auto;
    background: #f1f4f7;
  }
}
</style> 